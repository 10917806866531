import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileBookmark from './FileBookmark';
import SiteBookmark from './SiteBookmark';
import '../../../css/section/Bookmark.css';
import { restoreFileBookmark, restoreSiteBookmark } from '../../../api/bookmarkApi';
import axiosInstance from '../../../auth/axiosConfig';

const Bookmark = () => {
  const [isFileBookmark, setIsFileBookmark] = useState(
    sessionStorage.getItem('isFileBookmark') === 'false' ? false : true  // 기본=true (fileBookmark), 그 외=false (siteBookmark)
  );
  const [isRestoring, setIsRestoring] = useState(false);  // 복구 진행 중인지 상태
  const navigate = useNavigate();

  const handleBookmarkTitleClick = () => {
    navigate('/bookmark');
  };

  // 북마크 상태 저장
  const handleSetFileBookmark = (value) => {
    setIsFileBookmark(value);
    sessionStorage.setItem('isFileBookmark', value);  // sessionStorage에 북마크 상태 저장
  };

  // 파일 북마크 복구 함수
  const handleRestoreFileBookmark = async () => {
    if (isRestoring) return; // 이미 복구 중이면 실행 중지
    setIsRestoring(true); // 복구 진행 중 상태로 설정
    try {
      await restoreFileBookmark();  
      window.location.reload();
    } catch (error) {
      setIsRestoring(false);
      if (error.response && error.response.status === 404) {
        alert('복구할 항목이 없습니다. (휴지통이 비어있습니다)');
      } else {
        console.error('파일 복구 실패:', error);
      }
    }
  };

  // 사이트 북마크 복구 함수
  const handleRestoreSiteBookmark = async () => {
    if (isRestoring) return; // 이미 복구 중이면 실행 중지
    setIsRestoring(true); // 복구 진행 중 상태로 설정
    try {
      await restoreSiteBookmark();
      window.location.reload();
    } catch (error) {
      setIsRestoring(false);
      if (error.response && error.response.status === 404) {
        alert('복구할 항목이 없습니다. (휴지통이 비어있습니다)');
      } else {
        console.error('사이트 복구 실패:', error);
      }
    }
  };

    // 로그아웃 함수
  const handleLogout = async () => {
    try {
      await axiosInstance.post('/api/logout', {}, { withCredentials: true });
      navigate('/loginHome');  // 로그아웃 후 로그인 페이지로 이동
    } catch (error) {
      console.error('로그아웃 실패:', error);
    }
  };

  return (
    <div>
      <div className="bookmark-header">
        <h1 className="bookmark-title" onClick={handleBookmarkTitleClick}>
          [Bookmark]
        </h1>
        <div
          onClick={() => handleSetFileBookmark(true)}  // 파일 북마크 선택 시
          className={`bookmark-tab ${isFileBookmark ? 'active' : ''}`}
        >
          <h1 className="bookmark-tab-title">파일 /</h1>
        </div>
        <div
          onClick={() => handleSetFileBookmark(false)}  // 사이트 북마크 선택 시
          className={`bookmark-tab ${!isFileBookmark ? 'active' : ''}`}
        >
          <h1 className="bookmark-tab-title">사이트URL</h1>
        </div>
      </div>
      
      <div className="bookmark-content">
        {isFileBookmark ? <FileBookmark /> : <SiteBookmark />}
      </div>

      {/* 조건부 렌더링: 파일 북마크일 때 파일 복구 버튼, 사이트 북마크일 때 사이트 복구 버튼 */}
      <div className="restore-button-container">
        {isFileBookmark ? (
          <button onClick={handleRestoreFileBookmark} className="file-restore-button">
            복구
          </button>
        ) : (
          <button onClick={handleRestoreSiteBookmark} className="site-restore-button">
            복구
          </button>
        )}
      </div>
      <div
        style={{position: 'absolute',  top: '10px',  right: '20px',  fontSize: '12px',  color: 'blue',   cursor: 'pointer',    textDecoration: 'underline',  }}
        onClick={handleLogout}
      > 로그아웃
      </div>
    </div>
  );
};

export default Bookmark;

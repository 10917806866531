import axiosInstance from '../auth/axiosConfig';


const apiEndpoint = '/api/todos';

// Todo 항목 조회
export const getTodos = async () => {
  const response = await axiosInstance.get(apiEndpoint);
  return response.data;
};

// Todo 항목 추가
export const addTodo = async (todo) => {
  const response = await axiosInstance.post(apiEndpoint, todo, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Todo 항목 수정
export const updateTodo = async (id, todo) => {
  const response = await axiosInstance.put(`${apiEndpoint}/${id}`, todo, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Todo 삭제 주기 수정 
export const updateDeletionPeriod = async (deletionPeriod) => {
  const response = await axiosInstance.put(`${apiEndpoint}/schedule`, {
    deletionPeriod: deletionPeriod, 
  }, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Todo 항목 삭제
export const deleteTodo = async (id) => {
  await axiosInstance.delete(`${apiEndpoint}/${id}`);
};

// Todo 항목 완료
export const completeTodo = async (id) => {
  await axiosInstance.put(`${apiEndpoint}/${id}/complete`);
};

// 특정 날짜에 해당하는 Todo 항목들 삭제
export const deleteTodosByDate = async (date) => {
  await axiosInstance.delete(`${apiEndpoint}`, {
    data: { date: date },
    headers: { 'Content-Type': 'application/json' } 
  });
};


import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../css/section/ProjectMgmt.css';
import Task from './Task';
import {
  getProjects,
  createProject,
  updateProject,
  deleteProject,
  completeProject
} from '../../../api/projectMgmtApi';

const ProjectMgmt = () => {
  const [projects, setProjects] = useState([]);
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [hoverIndex, setHoverIndex] = useState(null);

  const navigate = useNavigate();

  const fetchProjects = useCallback(async () => {
    try {
      const projectData = await getProjects();
      setProjects(projectData);
    } catch (error) {
      console.error('프로젝트 불러오기 오류:', error);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);



  const handleAddProject = async () => {
    if (newProjectTitle.trim() !== '') {
        try {
            // 서버에 프로젝트 생성 요청
            const createdProject = await createProject({ title: newProjectTitle });
            setProjects((prevProjects) => [...prevProjects, createdProject]);
            setNewProjectTitle('');
            setSelectedProjectIndex(projects.length); // 새로 추가된 프로젝트를 선택
            await fetchProjects();
        } catch (error) {
            console.error('프로젝트 추가 오류:', error.response?.data || error.message);
        }
    }
};
  

  const handleEditProject = async (index, newTitle) => {
    if (newTitle.trim() === '') {
      alert('제목을 입력하세요.');
      return;
    }

    const updatedProjects = [...projects];
    updatedProjects[index] = { ...updatedProjects[index], title: newTitle };
    setProjects(updatedProjects); // UI에 즉시 반영
    setEditIndex(null); // 수정 모드 해제

    try {
      await updateProject(projects[index].id, newTitle);
    } catch (error) {
      console.error('프로젝트 수정 오류:', error.response?.data || error.message);
    }
  };

  const handleDeleteProject = async (index) => {
    const project = projects[index];
    const updatedProjects = projects.filter((_, i) => i !== index);
    setProjects(updatedProjects); // UI에서 즉시 제거

    try {
      await deleteProject(project.id);
      fetchProjects(); // 서버의 실제 데이터를 다시 가져옴
    } catch (error) {
      console.error('프로젝트 삭제 오류:', error.response?.data || error.message);
    }
  };

  const handleCompleteProjectUpdate = async (projectId, complete) => {
    try {
      await completeProject(projectId, complete); 
      setProjects((prevProjects) =>
        prevProjects.map((project, i) =>
          project.id === projectId ? { ...project, completed: complete } : project
        )
      );
    } catch (error) {
      console.error('프로젝트 완료 오류:', error.response?.data || error.message);
    }
  };


  const handleSelectProject = (index) => {
    // 선택된 프로젝트가 이미 열려 있는 경우에는 접기 위해 null로 설정
    setSelectedProjectIndex(selectedProjectIndex === index ? null : index);
  };

  const handleUpdateTasks = (index, tasks) => {
    const updatedProjects = projects.map((project, i) =>
      i === index ? { ...project, tasks } : project
    );
    setProjects(updatedProjects);
  };

  return (
    <div>
      <h1 className="title" onClick={() => navigate('/projectMgmt')} style={{ cursor: 'pointer' }}>Project 관리</h1>
      <div className="add-project">
        <input
          type="text"
          value={newProjectTitle}
          onChange={(e) => setNewProjectTitle(e.target.value)}
          placeholder="Enter project title"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddProject();
            }
          }}
          required
        />
        <button onClick={handleAddProject}>
          추가
        </button>
      </div>
      <div className="project-list">
        {projects.map((project, index) => (
          <div key={project.id}>
            <div
              className={`project-title ${editIndex === index ? 'editing' : ''}`}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              onClick={() => handleSelectProject(index)}
              onDoubleClick={() => {
                setEditIndex(index);
                setEditTitle(project.title);
              }}
            >
              {editIndex === index ? (
                <input
                  className="edit-input"
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  onBlur={() => handleEditProject(index, editTitle)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEditProject(index, editTitle);
                    }
                  }}
                  autoFocus
                />
              ) : (
                <span style={project.completed ? {
                  textDecoration: 'line-through',
                  textDecorationThickness: '2px', // 두께를 조정하는 속성
                  textDecorationColor: 'black', // 선의 색상을 조정
                  textShadow: '1px 0 black, -1px 0 black' // 선을 더 굵게 보이게 하는 트릭
                } : {}}>
                  {project.title}
                </span>
              )}
              {hoverIndex === index && editIndex !== index && selectedProjectIndex !== index && (
                <button className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteProject(index);
                  }}
                >
                  삭제
                </button>
              )}
              {selectedProjectIndex === index && (
                <button
                  className="complete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCompleteProjectUpdate(project.id, !project.completed);
                  }}
                >
                  {project.completed ? '취소' : '완료'}
                </button>
              )}
            </div>
            {selectedProjectIndex === index && (
              <Task
                project={project}
                updateTasks={(tasks) => handleUpdateTasks(index, tasks)}
                handleCompleteProjectUpdate={handleCompleteProjectUpdate}
              />   
            )}
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default ProjectMgmt;


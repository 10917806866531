import axios from 'axios';


const apiEndpoint = 'https://api.todo-pro.store/api/members';

// 회원가입 
export const signup = async (signupDto) => { 
    const response = await axios.post(`${apiEndpoint}`, signupDto, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,  // 쿠키 포함
    });
    return response; 
};

// 로그인 
export const login = async (loginDto) => { 
    const response = await axios.post(`${apiEndpoint}/login`, loginDto, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,  // 쿠키 포함
    });
    return response; 
};

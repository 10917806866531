import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../api/loginApi'; 
import '../../css/LoginHome.css'; 

const SignupPage = () => {
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();  // navigate 사용

    const handleSubmit = async (e) => {
        e.preventDefault();  // 기본 동작 방지
        try {
            const signupDto = { loginId, password };
            const response = await signup(signupDto);
            if (response.status === 200) {
                alert('회원가입 성공!');
                navigate('/loginHome');  // 페이지 이동
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                alert('이미 존재하는 아이디입니다.'); 
            } else {
                console.error('회원가입 오류:', error);
            }
        }
    };

    const handleCancel = () => {
        navigate('/loginHome');
    };

    return (
        <div className="auth-container">
            <h2>회원가입</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div>
                    <label>아이디: </label>
                    <input
                        type="text"
                        value={loginId}
                        onChange={(e) => setLoginId(e.target.value)}
                        required  
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <label>비밀번호: </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required  
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <button type="submit">확인</button>
                    <button type="button" onClick={handleCancel} style={{ marginLeft: '10px' }}>
                        취소
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SignupPage;

import React, { useState, useEffect, useCallback } from 'react';
import '../../../css/pages/ProjectMgmtPage.css';
import {
  createTaskDetail,
  updateTaskDetail,
  deleteTaskDetail,
  getTaskDetails,
  completeTaskDetail,
} from '../../../api/projectMgmtApi';

const TaskDetail = ({ task, updateTaskDetails, handleCompleteTaskUpdate }) => {
  const [details, setDetails] = useState([]);
  const [newDetail, setNewDetail] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editDetail, setEditDetail] = useState('');
  const [hoverIndex, setHoverIndex] = useState(null);
  const [completedDetails, setCompletedDetails] = useState([]);

  const fetchDetails = useCallback(async () => {
    try {
      const fetchedDetails = await getTaskDetails(task.id);
      setDetails(fetchedDetails);
      const completedIds = fetchedDetails.filter(d => d.completed).map(d => d.id);
      setCompletedDetails(completedIds);
    } catch (error) {
      console.error('Error fetching task details:', error.response?.data || error.message);
    }
  }, [task.id]);

  useEffect(() => {
    fetchDetails();
  }, [task.id, fetchDetails]);

  const handleAddDetail = async () => {
    if (newDetail.trim() === '') return;

    const tempId = Date.now(); // 임시 ID 생성
    const newDetailItem = { id: tempId, detail: newDetail };
    const updatedDetails = [...details, newDetailItem];
    setDetails(updatedDetails); // 즉시 상태 업데이트
    updateTaskDetails(updatedDetails);

    setNewDetail(''); // 입력 필드 초기화

    try {
      const createdDetail = await createTaskDetail(task.id, newDetail);
      const finalDetails = updatedDetails.map(d =>
        d.id === tempId ? createdDetail : d
      );
      setDetails(finalDetails); // 최종 상태 업데이트
      updateTaskDetails(finalDetails);
      // 세부 작업이 추가되었으므로 부모 작업의 완료 상태를 다시 확인
      const updatedCompletedDetails = completedDetails.filter(id => id !== createdDetail.id);
      setCompletedDetails(updatedCompletedDetails);
      // 부모 작업이 완료 상태였다면, 이제 미완료 상태로 업데이트해야 함
      if (task.completed) {
        await handleCompleteTaskUpdate(task.id, false);
      }
      await fetchDetails(); // 데이터 재로드
    } catch (error) {
      console.error('Error adding detail:', error.response?.data || error.message);
      const rolledBackDetails = updatedDetails.filter(d => d.id !== tempId);
      setDetails(rolledBackDetails); // 실패 시 롤백
      updateTaskDetails(rolledBackDetails);
    }
  };

  const handleEditDetail = async (index, updatedDetail) => {
    if (updatedDetail.trim() === '') return;

    const detailToEdit = details[index];
    const updatedDetails = details.map((d, i) =>
      i === index ? { ...d, detail: updatedDetail } : d
    );
    setDetails(updatedDetails); // 즉시 상태 업데이트
    updateTaskDetails(updatedDetails);

    setEditIndex(null);

    try {
      await updateTaskDetail(task.id, detailToEdit.id, updatedDetail);
    } catch (error) {
      console.error('Error updating detail:', error.response?.data || error.message);
      setDetails(details); // 실패 시 이전 상태로 롤백
      updateTaskDetails(details);
    }
  };

  const handleDeleteDetail = async (index) => {
    const detailToDelete = details[index];
    const updatedDetails = details.filter((_, i) => i !== index);
    setDetails(updatedDetails); // 즉시 상태 업데이트
    updateTaskDetails(updatedDetails);
  
    try {
      await deleteTaskDetail(task.id, detailToDelete.id);
      // 세부 작업이 삭제된 후, 남아 있는 세부 작업들이 모두 완료되었는지 확인
      const allCompleted = updatedDetails.every(detail => completedDetails.includes(detail.id));
      if (updatedDetails.length > 0) {
        // 남아있는 세부 작업들이 모두 완료되었다면, 부모 작업을 완료 상태로 업데이트
        if (allCompleted) {
          await handleCompleteTaskUpdate(task.id, true);
        } else if (!allCompleted) {
          await handleCompleteTaskUpdate(task.id, false);
        }
      }
  
    } catch (error) {
      console.error('Error deleting detail:', error.response?.data || error.message);
      setDetails(details); // 실패 시 이전 상태로 롤백
      updateTaskDetails(details);
    }
  };
  

  const handleCompleteDetail = async (detailId) => {
    const isCompleted = completedDetails.includes(detailId);
    try {
      await completeTaskDetail(task.id, detailId);
      const updatedCompletedDetails = isCompleted
        ? completedDetails.filter(id => id !== detailId)
        : [...completedDetails, detailId];
      setCompletedDetails(updatedCompletedDetails);  // 완료된 세부 작업 상태 업데이트

      // 모든 세부 작업이 완료되었는지 확인 후 부모 작업의 완료 상태 업데이트
      if (updatedCompletedDetails.length === details.length && details.length > 0) {
        await handleCompleteTaskUpdate(task.id, true);
      } else if (task.completed && updatedCompletedDetails.length !== details.length) {
        await handleCompleteTaskUpdate(task.id, false);
      }
    } catch (error) {
      console.error('Error completing task detail:', error.response?.data || error.message);
    }
  };

  return (
    <div className="task-details">
      {details.map((detail, index) => (
        <div
          key={detail.id}
          className={`task-detail-item ${hoverIndex === index ? 'hover' : ''} ${completedDetails.includes(detail.id) ? 'completed' : ''}`}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleCompleteDetail(detail.id);
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
            setEditIndex(index);
            setEditDetail(detail.detail);
          }}
        >
          {editIndex === index ? (
            <input
              type="text"
              value={editDetail}
              onChange={(e) => setEditDetail(e.target.value)}
              onBlur={() => handleEditDetail(index, editDetail)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEditDetail(index, editDetail);
                }
              }}
              autoFocus
              className="task-detail-input"
            />
          ) : (
            <span>{detail.detail}</span>
          )}
          {hoverIndex === index && editIndex !== index && (
            <button
              className="task-detail-delete-button"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteDetail(index);
              }}
            >
              삭제
            </button>
          )}
        </div>
      ))}
      <div className="add-task-detail">
        <input
          type="text"
          value={newDetail}
          onChange={(e) => setNewDetail(e.target.value)}
          placeholder="Enter task detail"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddDetail();
            }
          }}
          className="task-detail-input"
        />
      </div>
    </div>
  );
};

export default TaskDetail;

import axiosInstance from '../auth/axiosConfig';


const apiEndpoint = '/api/memos';

// Memo 항목 조회
export const getMemos = async () => {
  const response = await axiosInstance.get(apiEndpoint);
  return response.data;
};

// Memo 항목 추가
export const addMemo = async (memo) => {
  const response = await axiosInstance.post(apiEndpoint, memo, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Memo 항목 수정
export const updateMemo = async (id, memo) => {
  const response = await axiosInstance.put(`${apiEndpoint}/${id}`, memo, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Memo 항목 삭제
export const deleteMemo = async (id) => {
  await axiosInstance.delete(`${apiEndpoint}/${id}`);
};


//Memo 검색
export const searchMemo = async (keyword) => {
  const response = await axiosInstance.get(`${apiEndpoint}/search`, {
    params: {
      keyword: keyword,  
    },
  });
  return response.data;
};

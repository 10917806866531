import React from 'react';

// TodoSubsectionPage : 이전, 이후 todo

const TodoSubsectionPage = ({
  title, 
  todos, 
  section, 
  handleCompleteTodo, 
  handleTodoClick, 
  editId, 
  editTask, 
  setEditTask, 
  handleEditTodo, 
  handleDeleteTodo, 
  handleDeleteTodosByDate
}) => {

  // 요일을 가져오는 함수
  const getDayName = (dateString) => {
    const date = new Date(dateString);
    const days = ['일', '월', '화', '수', '목', '금', '토']; // 요일 배열
    return days[date.getDay()]; // 해당 날짜의 요일 반환
  };

  // todos를 날짜별로 그룹화하는 함수
  const groupTodosByDate = (todos) => {
    return todos.reduce((acc, todo) => {
      const currentDate = todo.executeDate.split('T')[0];
      if (!acc[currentDate]) {
        acc[currentDate] = [];
      }
      acc[currentDate].push(todo);
      return acc;
    }, {});
  };

  // 그룹화된 todos를 가져옴
  const groupedTodos = groupTodosByDate(todos);

  // 그룹화된 todos를 렌더링하는 함수
  const renderGroupedTodos = (groupedTodos) => {
    return Object.keys(groupedTodos).map((date, index) => {
      const todosForDate = groupedTodos[date];
      const dayName = getDayName(todosForDate[0].executeDate);
      const displayDate = `${date} (${dayName})`;

      return (
        <div key={date}>
          <div className="todo-page-date-section">
            {index > 0 && <hr className="todo-page-full-width-hr" />} {/* 첫 번째 항목이 아닌 경우 구분선을 출력 */}
            <h4 className="todo-page-date-title">
              {displayDate} {/* 날짜 및 요일 출력 */}
              <button
                className="todo-page-date-delete-button"
                onClick={() => handleDeleteTodosByDate(date)} // 날짜 옆 삭제 버튼 클릭 시 해당 날짜의 모든 Todo 삭제
              >
                삭제
              </button>
            </h4>
          </div>
          <ul>
            {todosForDate.map((todo) => (
              <li key={todo.id} className={`todo-page-item ${todo.completed ? 'todo-page-completed' : ''}`}>
                {editId === todo.id ? (
                  <textarea
                    value={editTask}
                    onChange={(e) => setEditTask(e.target.value)} // 수정 중인 텍스트 업데이트
                    onBlur={() => handleEditTodo(todo.id, editTask, section)} // 포커스 잃으면 수정 완료
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleEditTodo(todo.id, editTask, section); // 엔터키로 수정 완료
                    }}
                    className="todo-page-textarea"
                  />
                ) : (
                  <>
                    <span
                      onDoubleClick={() => handleTodoClick(todo.id, todo.task, section)} // 더블클릭으로 수정 모드 전환
                      onClick={() => handleCompleteTodo(todo.id, section)} // 클릭으로 완료 상태 변경
                    >
                      {todo.task}
                    </span>
                    <div className="todo-page-buttons">
                      <button onClick={() => handleDeleteTodo(todo.id, section)}>삭제</button> {/* 각 Todo 항목의 삭제 버튼 */}
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      );
    });
  };

  return (
    <div className="todo-page-subsection">
      <h2 className="todo-page-section-title">{title}</h2> {/* 섹션 제목 출력 */}
      <hr className="todo-page-title-hr" /> {/* 제목 아래 구분선 */}
      {renderGroupedTodos(groupedTodos)} {/* 그룹화된 각 날짜별 Todo 항목을 렌더링 */}
    </div>
  );
};

export default TodoSubsectionPage;

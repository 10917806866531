import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Todo from './components/section/todo/Todo';
import Bookmark from './components/section/bookmark/Bookmark';
import Memo from './components/section/memo/Memo';
import ProjectMgmt from './components/section/projectMgmt/ProjectMgmt';
import TodoPage from './components/pages/TodoPage';
import BookmarkPage from './components/pages/BookmarkPage';
import MemoPage from './components/pages/MemoPage';
import ProjectMgmtPage from './components/pages/projectMgmt/ProjectMgmtPage';
import LoginHomePage from './components/login/LoginHomePage';
import LoginPage from './components/login/LoginPage';
import SignupPage from './components/login/SignupPage';
import { AuthProvider} from './auth/AuthContext';
import PrivateRoute from './auth/PrivateRoute';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* 로그인 여부에 따라 경로 처리 */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <div className="app-container">
                  <div className="section">
                    <Todo />
                  </div>
                  <div className="section">
                    <Bookmark />
                  </div>
                  <div className="section">
                    <Memo />
                  </div>
                  <div className="section">
                    <ProjectMgmt />
                  </div>
                </div>
              </PrivateRoute>
            }
          />
          {/* 각 개별 페이지 경로 설정 */}
          <Route path="/" element={<PrivateRoute><TodoPage /></PrivateRoute>} />
          <Route path="/todo" element={<PrivateRoute><TodoPage /></PrivateRoute>} />
          <Route path="/bookmark" element={<PrivateRoute><BookmarkPage /></PrivateRoute>} />
          <Route path="/memo" element={<PrivateRoute><MemoPage /></PrivateRoute>} />
          <Route path="/projectMgmt" element={<PrivateRoute><ProjectMgmtPage /></PrivateRoute>} />
          {/* 로그인 페이지 */}
          <Route path="/loginHome" element={<LoginHomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

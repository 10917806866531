import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getSiteBookmarks, addSiteBookmark, updateSiteBookmark, softDeleteSiteBookmark, searchSiteBookmark} from '../../../api/bookmarkApi';

const SiteSection = () => {
  const [siteBookmarks, setSiteBookmarks] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [url, setUrl] = useState('');
  const [editSiteName, setEditSiteName] = useState('');
  const [editUrl, setEditUrl] = useState('');
  const [editId, setEditId] = useState(null);
  const inputRef = useRef(null);
  const [keyword, setKeyword] = useState(''); // 검색어 상태

  const fetchSiteBookmarks = useCallback(async () => {
    try {
      const data = await getSiteBookmarks();
      const filteredData = data.filter(bookmark => !bookmark.deleted); 
      setSiteBookmarks(filteredData);
    } catch (error) {
      console.error('Failed to fetch site bookmarks:', error);
    }
  }, []);

  useEffect(() => {
    fetchSiteBookmarks();
  }, [fetchSiteBookmarks]);

  const handleSiteBookmarkAdd = async (e) => {
    e.preventDefault();
    try {
      await addSiteBookmark(siteName, url);
      fetchSiteBookmarks();
      setSiteName('');
      setUrl('');
      inputRef.current.value = '';
    } catch (error) {
      console.error('Failed to add site bookmark:', error);
    }
  };

  const handleEditSubmit = async (bookmarkId) => {
    setSiteBookmarks((prevBookmarks) =>
      prevBookmarks.map((bookmark) =>
        bookmark.id === bookmarkId ? { ...bookmark, siteName: editSiteName, url: editUrl } : bookmark
      )
    );

    setEditId(null);
    setEditSiteName('');
    setEditUrl('');

    try {
      await updateSiteBookmark(bookmarkId, editSiteName, editUrl);
    } catch (error) {
      console.error('Failed to update site bookmark:', error);
      fetchSiteBookmarks();
    }
  };

  const handleDeleteClick = async (bookmarkId) => {
    try {
      await softDeleteSiteBookmark(bookmarkId);
      fetchSiteBookmarks();
    } catch (error) {
      console.error('Failed to delete site bookmark:', error);
    }
  };
 

  const handleBookmarkClick = (url, isEditMode) => {
    if (!isEditMode) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

   // 북마크 검색
   const handleSearch = async () => {
    if (!keyword.trim()) { // trim()을 사용해 공백 제거 후 검사
      window.location.reload();
      return; 
    }
    try {
      const data = await searchSiteBookmark(keyword);
      setSiteBookmarks(data);  // 검색 결과를 상태에 저장
      setKeyword('');  // 검색 완료 후 검색창 비우기
    } catch (error) {
      console.error("Failed to search file bookmarks:", error);
    }
  };


  return (
    <div>
      {/* 검색어 입력 및 검색 버튼 추가 */}
      <div className="site-search-section">
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)} // 검색어 상태 업데이트
          placeholder="검색어 입력"
          className="site-search-input"
        />
        <button onClick={handleSearch} className="site-search-button">검색</button>
      </div>
      <form onSubmit={handleSiteBookmarkAdd} className="site-upload-form">
        <input 
          type="text" 
          value={siteName} 
          onChange={(e) => setSiteName(e.target.value)} 
          placeholder="Site Name" 
          required 
          style={{ display: 'block', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input 
            type="url" 
            value={url} 
            onChange={(e) => setUrl(e.target.value)} 
            placeholder="URL" 
            required 
          />
          <button type="submit">추가</button>
        </div>
      </form>
      <ul className="site-bookmarks">
        {siteBookmarks.map((bookmark) => (
          <li 
            key={bookmark.id} 
            className="site-bookmark" 
            onClick={() => handleBookmarkClick(bookmark.url, editId === bookmark.id)} // 수정 모드가 아닌 경우에만 URL 이동
          >
            {editId === bookmark.id ? (
              <div>
                <input
                  type="text"
                  value={editSiteName}
                  onChange={(e) => setEditSiteName(e.target.value)}
                />
                <input
                  type="url"
                  value={editUrl}
                  onChange={(e) => setEditUrl(e.target.value)}
                />
                <button onClick={() => handleEditSubmit(bookmark.id)}>Save</button>
              </div>
            ) : (
              <div>
                <a 
                  href={bookmark.url}
                  className="site-name"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {bookmark.siteName}
                </a>
              </div>
            )}
            {editId !== bookmark.id && (
              <div className="site-buttons">
                <button onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(bookmark.id);
                }}>삭제</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SiteSection;

import axiosInstance from '../auth/axiosConfig';


export const getSettings = async (id) => {
    const response = await axiosInstance.get(`/api/settings/${id}`);
    return response.data;
};

export const updateSetting = async (id, value) => {
    const response = await axiosInstance.put(`/api/settings/${id}`, null, {
        params: { value: value },
    });
    return response.data;
};

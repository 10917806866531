import axiosInstance from '../auth/axiosConfig';




// 프로젝트 목록 조회
export const getProjects = async () => {
  const endpoint = '/api/project-mgmt/projects';
  try {
    const response = await axiosInstance.get(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    console.log('프로젝트 데이터:', response.data); // 데이터를 콘솔에 출력
    return response.data;
  } catch (error) {
    console.error('프로젝트 목록 조회 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 새로운 프로젝트 추가
export const createProject = async (project) => {
  const endpoint = '/api/project-mgmt/projects';
  try {
    const response = await axiosInstance.post(endpoint, project, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('프로젝트 생성 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 프로젝트 업데이트
export const updateProject = async (id, title) => {
  const endpoint = `/api/project-mgmt/projects/${id}`;
  try {
    const response = await axiosInstance.put(endpoint, { title }, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('프로젝트 업데이트 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 프로젝트 완료
export const completeProject = async (id) => {
  const endpoint = `/api/project-mgmt/projects/${id}/complete`;
  try {
    const response = await axiosInstance.put(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('프로젝트 완료 설정 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 프로젝트 삭제
export const deleteProject = async (id) => {
  const endpoint = `/api/project-mgmt/projects/${id}`;
  try {
    const response = await axiosInstance.delete(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('프로젝트 삭제 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 태스크 관련 API

// 특정 프로젝트에 새로운 Task 추가
export const createTask = async (projectId, name) => {
  const endpoint = `/api/project-mgmt/projects/${projectId}/tasks`;
  try {
    const response = await axiosInstance.post(endpoint, { name }, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Task 생성 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 특정 프로젝트의 Task 목록 조회
export const getTasks = async (projectId) => {
  const endpoint = `/api/project-mgmt/projects/${projectId}/tasks`;
  try {
    const response = await axiosInstance.get(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Task 목록 조회 오류:', error.response?.data || error.message);
    throw error;
  }
};

// Task 업데이트
export const updateTask = async (projectId, taskId, name) => {
  const endpoint = `/api/project-mgmt/projects/${projectId}/tasks/${taskId}`;
  try {
    const response = await axiosInstance.put(endpoint, { name }, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Task 업데이트 오류:', error.response?.data || error.message);
    throw error;
  }
};

// task 완료
export const completeTask = async (projectId, taskId) => {
  const endpoint = `/api/project-mgmt/projects/${projectId}/tasks/${taskId}/complete`;
  try {
    const response = await axiosInstance.put(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Task 완료 설정 오류:', error.response?.data || error.message);
    throw error;
  }
};


// Task 삭제
export const deleteTask = async (projectId, taskId) => {
  const endpoint = `/api/project-mgmt/projects/${projectId}/tasks/${taskId}`;
  try {
    const response = await axiosInstance.delete(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Task 삭제 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 태스크 디테일 관련 API

// 특정 Task의 TaskDetail 목록 조회
export const getTaskDetails = async (taskId) => {
  const endpoint = `/api/project-mgmt/tasks/${taskId}/details`;
  try {
    const response = await axiosInstance.get(endpoint, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('TaskDetail 목록 조회 오류:', error.response?.data || error.message);
    throw error;
  }
};

// 특정 Task에 새로운 TaskDetail 추가
export const createTaskDetail = async (taskId, detail) => {
  const endpoint = `/api/project-mgmt/tasks/${taskId}/details`;
  try {
    const response = await axiosInstance.post(endpoint, { detail }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('TaskDetail 생성 오류:', error.response?.data || error.message);
    throw error;
  }
};

// TaskDetail 업데이트
export const updateTaskDetail = async (taskId, detailId, detail) => {
  const endpoint = `/api/project-mgmt/tasks/${taskId}/details/${detailId}`;
  try {
    const response = await axiosInstance.put(endpoint, { detail }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('TaskDetail 업데이트 오류:', error.response?.data || error.message);
    throw error;
  }
};

// task 완료
export const completeTaskDetail = async (taskId, detailId) => {
  const endpoint =  `/api/project-mgmt/tasks/${taskId}/details/${detailId}/complete`;
  try {
    const response = await axiosInstance.put(endpoint, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('TaskDetail 완료 설정 오류:', error.response?.data || error.message);
    throw error;
  }
};


// TaskDetail 삭제
export const deleteTaskDetail = async (taskId, detailId) => {
  const endpoint = `/api/project-mgmt/tasks/${taskId}/details/${detailId}`;
  try {
    const response = await axiosInstance.delete(endpoint, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('TaskDetail 삭제 오류:', error.response?.data || error.message);
    throw error;
  }
};

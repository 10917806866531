import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMemos, addMemo, updateMemo, deleteMemo, searchMemo } from '../../api/memoApi';
import '../../css/pages/MemoPage.css'; // CSS 파일 이름도 변경

const MAX_CONTENT_LENGTH = 65535;

const MemoPage = () => { // 컴포넌트 이름 변경
  const [memos, setMemos] = useState([[], []]); 
  const [visibleDeleteButton, setVisibleDeleteButton] = useState(null); 
  const [activeTextarea, setActiveTextarea] = useState(null); 
  const memosRef = useRef(memos);  // useRef로 memos를 참조
  const [keyword, setKeyword] = useState(''); // 검색어 상태

  const navigate = useNavigate();

  // memos 상태가 변경될 때마다 memosRef를 업데이트
  useEffect(() => {
    memosRef.current = memos;
  }, [memos]);

  // 메모를 서버에서 가져오는 함수
  const fetchMemos = useCallback(async () => {
    try {
      const data = await getMemos(); 
      const leftMemos = data.filter((_, index) => index % 2 === 0); 
      const rightMemos = data.filter((_, index) => index % 2 !== 0); 
      setMemos([leftMemos, rightMemos]); 
    } catch (error) {
      console.error('Failed to fetch memos:', error);
    }
  }, []);

  useEffect(() => {
    fetchMemos(); 
  }, [fetchMemos]);

  // 새로운 메모를 추가하는 함수
  const handleAddMemo = () => {
    const newMemo = { id: null, content: '', isNew: true };
    setMemos((prevMemos) => {
      const [leftMemos, rightMemos] = prevMemos;
      if (leftMemos.length <= rightMemos.length) {
        return [[...leftMemos, newMemo], rightMemos];
      } else {
        return [leftMemos, [...rightMemos, newMemo]];
      }
    });

    // 비동기적으로 서버에 메모 추가
    addMemo(newMemo).then(() => {
      fetchMemos();
    }).catch(error => {
      console.error('Failed to add memo:', error);
    });
  };

  // 메모 내용이 변경될 때 상태를 업데이트하는 함수
  const handleMemoContentChange = (side, index, value) => {
    if (value.length <= MAX_CONTENT_LENGTH) {
      setMemos((prevMemos) => {
        const newMemos = [...prevMemos];
        newMemos[side] = [...newMemos[side]];
        newMemos[side][index] = { ...newMemos[side][index], content: value };
        return newMemos;
      });
    } else {
      console.error(`Content length exceeds ${MAX_CONTENT_LENGTH} characters`);
    }
  };

  // 메모를 저장하는 함수 (새 메모인 경우 추가, 기존 메모인 경우 수정)
  const handleSaveMemo = async (side, index) => {
    const memoToSave = memos[side][index];
  
    if (memoToSave.isNew) {
      // 클라이언트에서 먼저 상태 업데이트
      setMemos((prevMemos) => {
        const newMemos = [...prevMemos];
        newMemos[side] = [...newMemos[side]];
        newMemos[side][index] = { ...newMemos[side][index], isNew: false }; 
        return newMemos;
      });

      // 비동기적으로 서버에 메모 추가
      addMemo({ content: memoToSave.content }).then(() => {
        fetchMemos(); // 메모 추가 후 최신 메모 목록을 다시 불러옴
      }).catch(error => {
        console.error('Failed to save memo:', error);
      });
    } else {
      await handleEditMemo(side, index); // 기존 메모는 수정
    }
  };

  // 메모를 수정하는 함수
  const handleEditMemo = useCallback(async (side, index) => {
    const memoToEdit = memosRef.current[side][index];
    if (memoToEdit.id !== undefined && memoToEdit.id !== null) {
      // 클라이언트에서 먼저 상태 업데이트
      setMemos((prevMemos) => {
        const newMemos = [...prevMemos];
        newMemos[side] = [...newMemos[side]];
        newMemos[side][index] = { ...memoToEdit, isNew: false }; 
        return newMemos;
      });

      // 비동기적으로 서버에 메모 수정
      updateMemo(memoToEdit.id, { content: memoToEdit.content }).then(() => {
        fetchMemos();
      }).catch(error => {
        console.error('Failed to update memo:', error);
      });
    } else {
      console.error('Memo ID is undefined or null');
    }
  }, [fetchMemos]);

  // 메모를 삭제하는 함수
  const handleDeleteMemo = useCallback((side, index) => {
    const memoId = memosRef.current[side][index]?.id;

    // UI에서 먼저 메모 삭제
    setMemos((prevMemos) => {
      const newMemos = [...prevMemos];
      newMemos[side] = newMemos[side].filter((_, i) => i !== index); 
      return newMemos;
    });

    // 비동기적으로 서버에 메모 삭제
    if (memoId) {
      deleteMemo(memoId).then(() => {
        fetchMemos(); // 서버에서 삭제 후 최신 메모 목록 다시 불러옴
      }).catch(error => {
        console.error('Failed to delete memo:', error);
      });
    } else {
      console.error('Memo ID is null or undefined. Cannot delete this memo.');
    }

    setVisibleDeleteButton(null);
    setActiveTextarea(null);
  }, [fetchMemos]);

  const handleActivateTextarea = (side, index, event) => {
    event.stopPropagation(); 
    setVisibleDeleteButton(`${side}-${index}`);
    setActiveTextarea(`${side}-${index}`);
  };

  const handleDeactivateTextarea = () => {
    setVisibleDeleteButton(null);
    setActiveTextarea(null);
  };

  // 검색
const handleSearch = async () => {
  if (!keyword.trim()) { // trim()을 사용해 공백 제거 후 검사
    window.location.reload();
    return; 
  }
  try {
    const data = await searchMemo(keyword);
    
    // 검색 결과를 좌우 배열로 나누기
    const leftMemos = data.filter((_, index) => index % 2 === 0);
    const rightMemos = data.filter((_, index) => index % 2 !== 0);
    
    setMemos([leftMemos, rightMemos]);  // 검색 결과를 상태에 저장
    setKeyword('');  // 검색 완료 후 검색창 비우기
  } catch (error) {
    console.error("Failed to search memos:", error);
  }
};

  useEffect(() => {
    console.log('Memos updated:', memos); 
  }, [memos]);

  return (
    <div className="memo-page-container" onClick={() => handleDeactivateTextarea()}>
      <div className="memo-page-header">
      <h1 className="memo-page-title" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}> Memo</h1>
        <button onClick={handleAddMemo} className="memo-page-button">추가</button>
      </div>
      <hr className="memo-page-title-hr" />
      {/* 검색어 입력 및 검색 버튼 추가 */}
      <div className="memo-page-search-section">
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)} // 검색어 상태 업데이트
          placeholder="검색어 입력"
          className="memo-page-search-input"
        />
        <button onClick={handleSearch} className="memo-page-search-button">검색</button>
      </div>
      <div className="memo-page-list">
        <div className="memo-page-column">
          {memos[0].map((memo, index) => (
            <div key={`left-${index}`} className="memo-page-item" style={{ position: 'relative' }}>
              <textarea
                value={memo.content}
                onChange={(e) => handleMemoContentChange(0, index, e.target.value)}
                onBlur={() => handleSaveMemo(0, index)} 
                onClick={(event) => handleActivateTextarea(0, index, event)}
                className="memo-page-textarea"
                style={{ height: '450px', overflow: activeTextarea === `0-${index}` ? 'auto' : 'hidden' }} 
                readOnly={activeTextarea !== `0-${index}`} 
              />
              {visibleDeleteButton === `0-${index}` && (
                <button
                  onClick={(event) => {
                    event.stopPropagation(); 
                    handleDeleteMemo(0, index);
                  }}
                  className="memo-page-delete-button"
                >
                  삭제
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="memo-page-column">
          {memos[1].map((memo, index) => (
            <div key={`right-${index}`} className="memo-page-item" style={{ position: 'relative' }}>
              <textarea
                value={memo.content}
                onChange={(e) => handleMemoContentChange(1, index, e.target.value)}
                onBlur={() => handleSaveMemo(1, index)} 
                onClick={(event) => handleActivateTextarea(1, index, event)} 
                className="memo-page-textarea"
                style={{ height: '450px', overflow: activeTextarea === `1-${index}` ? 'auto' : 'hidden' }} 
                readOnly={activeTextarea !== `1-${index}`} 
              />
              {visibleDeleteButton === `1-${index}` && (
                <button
                  onClick={(event) => {
                    event.stopPropagation(); 
                    handleDeleteMemo(1, index);
                  }}
                  className="memo-page-delete-button"
                >
                  삭제
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemoPage;

import axios from 'axios';

/**
 * HTTP 요청/응답 관리
 */




// HTTP 요청과 응답을 보호 (쿠키 기반 인증)
const axiosInstance = axios.create({
  baseURL: 'https://api.todo-pro.store', 
  withCredentials: true,  // 쿠키를 포함하여 서버와 통신
});



// 리프레시 토큰 요청 함수
const reissueToken = async () => {
  try {
      const response = await axiosInstance.post('/reissue');  // 리프레시 토큰을 통해 액세스 토큰 재발급
      return response; 
  } catch (error) {
      console.error('Error reissuing token:', error);
      throw error;  
  }
};



// 응답 인터셉터 (서버 응답 처리)
axiosInstance.interceptors.response.use(
  response => {  // 응답 성공 시
      return response;
  },
  async error => {  // 응답 실패 시
      const originalRequest = error.config;  // 실패한 요청 정보

      if (error.response?.status === 401 && !originalRequest._retry) {   // 401 에러 & 재시도 안 했을 때
          const errorMessage = error.response.data.message;  // 서버로부터 받은 오류 메시지
          
          if (errorMessage === "Access token has expired") {  // 엑세스 토큰 만료 처리
              originalRequest._retry = true;  // 재시도 플래그 설정
              try {
                  const response = await reissueToken();  // 리프레시 토큰으로 액세스 토큰 재발급 요청
                  if (response.status === 200) {
                      console.log("Access token reissued, retrying original request.");
                      return axiosInstance(originalRequest);  // 액세스 토큰 재발급 후 원래 요청 다시 시도
                  }
              } catch (reissueError) {  // 리프레시 토큰도 만료되었을 때 처리
                  const reissueErrorMessage = reissueError.response?.data?.message;
                  console.log(reissueErrorMessage);
                  
                  if (reissueErrorMessage === "Refresh token has expired") {  // 리프레시 토큰 만료 처리
                      console.log("Refresh token has expired, redirecting to login...");
                      window.location.href = '/loginHome';  // 로그인 페이지로 리다이렉트
                  } else if (reissueErrorMessage === "Abnormal access") {  // 비정상 접근 처리
                      alert("비정상적 접근이 감지되었습니다.\n 다시 로그인 해주세요.");
                  }
              }
          }
      }
      return Promise.reject(error);  // 다른 에러는 그대로 전달
  }
);

export default axiosInstance;

import React from 'react';
import { useNavigate } from 'react-router-dom';  
import '../../css/LoginHome.css';  // 스타일을 위한 CSS 파일 추가

const LoginHomePage = () => {
    const navigate = useNavigate(); 
    return (
        <div className="login-home-container">
            <h1 className="login-title">Todo Home</h1>
            <div className="button-container">
                <button className="login-button" onClick={() => navigate('/login')}>로그인</button>
                <button className="signup-button" onClick={() => navigate('/signup')}>회원가입</button>
            </div>
        </div>
    );
};

export default LoginHomePage;

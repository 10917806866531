import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TodoSubsection from './TodoSubsection'; // 이전, 이후 Todo 섹션을 나타내는 컴포넌트
import '../../../css/section/Todo.css'; // Todo 컴포넌트에 적용할 CSS 파일
import { getTodos, addTodo, updateTodo, deleteTodo, completeTodo, deleteTodosByDate } from '../../../api/todoApi'; // Todo 관련 API 함수들

// 오늘Todo


const Todo = () => {
  // 상태 관리를 위한 useState 훅들
  const [todos, setTodos] = useState({ todayTodos: [], beforeTodos: [], afterTodos: [] }); // 오늘, 이전, 이후 Todo 리스트 상태
  const [task, setTask] = useState(''); // 새로운 Todo 항목의 텍스트 상태
  const [editTask, setEditTask] = useState(''); // 수정 중인 Todo 항목의 텍스트 상태
  const [editId, setEditId] = useState(null); // 현재 수정 중인 Todo 항목의 ID 상태
  
  const navigate = useNavigate();

  // 현재 날짜를 'YYYY-MM-DD' 형식으로 설정
  const today = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState(today); // 새로운 Todo 항목의 날짜 상태

  // 현재 날짜와 요일을 가져오는 함수
  const getFormattedDateWithDay = () => {
    const date = new Date();
    const days = ['일', '월', '화', '수', '목', '금', '토']; // 요일 배열
    const day = days[date.getDay()]; // 오늘의 요일
    console.log(date.toISOString().split('T')[0])
    return `${date.toISOString().split('T')[0]} (${day})`; // 'YYYY-MM-DD (요일)' 형식으로 반환
  };

  // 서버에서 Todo 리스트를 가져오는 함수
  const fetchTodos = useCallback(async () => {
    try {
      const data = await getTodos(); // API 호출하여 데이터 가져옴
      setTodos(data); // 가져온 데이터를 상태에 저장
    } catch (error) {
      console.error('Fetching todos failed:', error); // 에러 발생 시 콘솔에 출력
    }
  }, []);

  // 컴포넌트가 처음 렌더링될 때 Todo 리스트를 가져옴
  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  // 새로운 Todo 항목을 추가하는 함수
  const handleAddTodo = useCallback(async () => {
    if (!task || !date || editId !== null) return; // 입력값이 없거나 수정 중이면 함수 종료
    const executeDate = `${date}T00:00:00`; // 실행 날짜를 'YYYY-MM-DDT00:00:00' 형식으로 설정
    const newTodo = { task, executeDate, completed: false }; // 새로운 Todo 항목 생성
    try {
      await addTodo(newTodo); // 서버에 새로운 Todo 항목 추가
      setTask(''); // 입력 필드 초기화
      fetchTodos(); // 추가 후 Todo 리스트를 다시 가져옴
    } catch (error) {
      console.error('Adding todo failed:', error); // 에러 발생 시 콘솔에 출력
    }
  }, [task, date, editId, fetchTodos]);

  // Todo 항목의 완료 상태를 변경하는 함수
  const handleCompleteTodo = useCallback(async (id, section) => {
    try {
      await completeTodo(id); // 서버에서 완료 상태 업데이트
      fetchTodos(); // 업데이트 후 Todo 리스트를 다시 가져옴
    } catch (error) {
      console.error('Updating todo failed:', error); // 에러 발생 시 콘솔에 출력
    }
  }, [fetchTodos]);

  // Todo 항목을 수정하는 함수
  const handleEditTodo = useCallback(async (id, newTask, section) => {
    if (!newTask.trim()) {   // 공백으로 수정된 경우 해당 항목을 삭제
      try {
        await deleteTodo(id); 
        setTodos(prevTodos => ({
          ...prevTodos,
          [section]: prevTodos[section].filter(todo => todo.id !== id) // 상태에서도 해당 Todo 삭제
        }));
      } catch (error) {
        console.error('Deleting todo failed:', error); 
      }
      return; 
    }

    const updatedTodos = todos[section].map(todo =>
      todo.id === id 
        ? { ...todo, task: newTask } // 기존의 executeDate를 유지하면서 task만 업데이트
        : todo
    );
    setTodos(prevTodos => ({ ...prevTodos, [section]: updatedTodos })); // 업데이트된 Todo 리스트를 상태에 저장
    setEditId(null); // 수정 모드 종료
    setEditTask(''); // 수정 텍스트 필드 초기화
    try {
      await updateTodo(id, { task: newTask, executeDate: updatedTodos.find(todo => todo.id === id).executeDate }); // 서버에 수정된 Todo 항목 저장, executeDate 유지
    } catch (error) {
      console.error('Editing todo failed:', error); // 에러 발생 시 콘솔에 출력
    }
  }, [todos]);

  // Todo 항목을 삭제하는 함수
  const handleDeleteTodo = useCallback(async (id, section) => {
    setTodos(prevTodos => ({
      ...prevTodos,
      [section]: prevTodos[section].filter(todo => todo.id !== id) // 선택된 Todo 항목을 리스트에서 제거
    }));
    try {
      await deleteTodo(id); // 서버에서 Todo 항목 삭제
      fetchTodos(); // 삭제 후 Todo 리스트를 다시 가져옴
    } catch (error) {
      console.error('Deleting todo failed:', error); // 에러 발생 시 콘솔에 출력
    }
  }, [fetchTodos]);
  
  
 // 이전, 이후todo 특정 날짜의 모든 Todo 항목을 삭제하는 함수
  const handleDeleteTodosByDate = async (date) => {
    try {
      await deleteTodosByDate(date); // API 호출하여 특정 날짜의 Todo 항목 삭제
      fetchTodos(); // 삭제 후 Todo 리스트를 다시 가져옴
    } catch (error) {
      console.error('Error deleting todos:', error);
    }
  };

  

  // Todo 항목을 클릭하여 수정 모드로 전환하는 함수
  const handleTodoClick = useCallback((id, task, section) => {
    if (editId === id) {
      handleEditTodo(id, editTask, section); // 이미 수정 중인 경우 수정 완료 처리
    } else {
      setEditId(id); // 수정할 Todo 항목의 ID 설정
      setEditTask(task); // 수정할 텍스트를 입력 필드에 설정
    }
  }, [editId, editTask, handleEditTodo]);

  return (
    <div className="todo-container">
      <div className="todo-left">
      <h1 onClick={() => navigate('/todo')} style={{ cursor: 'pointer' }}>오늘 Todo</h1> {/* Add onClick event */}
        <hr className="title-hr" />
        <div className="date-display">
          {getFormattedDateWithDay()} {/* 날짜와 요일을 표시 */}
          <br></br><br></br>
        </div>
        <div className="todo-left-content">
          <ul className="todo-list">
            {todos.todayTodos.map(todo => (
              <li key={todo.id} className={`todo-item ${todo.completed ? 'completed' : ''}`}>
                {editId === todo.id ? (
                  <textarea
                    value={editTask}
                    onChange={(e) => setEditTask(e.target.value)}
                    onBlur={() => handleEditTodo(todo.id, editTask, 'todayTodos')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleEditTodo(todo.id, editTask, 'todayTodos'); // 엔터키를 누르면 수정 완료
                    }}
                    className="todo-textarea"
                  />
                ) : (
                  <>
                    <span
                      onDoubleClick={() => handleTodoClick(todo.id, todo.task, 'todayTodos')} // 더블클릭으로 수정 모드 전환
                      onClick={() => handleCompleteTodo(todo.id, 'todayTodos')} // 클릭으로 완료 상태 변경
                    >
                      {todo.task}
                    </span>
                    {/* <div className="buttons"> */}
                      {/* <button onClick={() => handleDeleteTodo(todo.id, 'todayTodos')}>삭제</button>  */}
                    {/* </div> */}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="todo-right">
        <div className="todo-inputs">
          <input
            type="date"
            value={date}
            onChange={e => setDate(e.target.value)} // 날짜 입력 필드 변경 시 상태 업데이트
          />
          <input
            type="text"
            value={task}
            onChange={e => setTask(e.target.value)} // 텍스트 입력 필드 변경 시 상태 업데이트
            placeholder="할 일"
          />
          <button onClick={handleAddTodo}>추가</button> {/* 새로운 Todo 항목 추가 */}
        </div>
        <div className="todo-subcontainer">
          <TodoSubsection
            title="이전 Todo"
            todos={todos.beforeTodos}
            section="beforeTodos"
            handleCompleteTodo={handleCompleteTodo}
            handleTodoClick={handleTodoClick}
            editId={editId}
            editTask={editTask}
            setEditTask={setEditTask}
            handleEditTodo={handleEditTodo}
            handleDeleteTodo={handleDeleteTodo}
            handleDeleteTodosByDate={handleDeleteTodosByDate}
          />
          <TodoSubsection
            title="이후 Todo"
            todos={todos.afterTodos}
            section="afterTodos"
            handleCompleteTodo={handleCompleteTodo}
            handleTodoClick={handleTodoClick}
            editId={editId}
            editTask={editTask}
            setEditTask={setEditTask}
            handleEditTodo={handleEditTodo}
            handleDeleteTodo={handleDeleteTodo}
            handleDeleteTodosByDate={handleDeleteTodosByDate}
          />
        </div>
      </div>
    </div>
  );
};

export default Todo;

import React, { useState, useEffect, useCallback } from 'react';
import '../../../css/pages/ProjectMgmtPage.css';
import TaskDetailPage from './TaskDetailPage'
import {
  createTask,
  updateTask,
  deleteTask,
  getTasks,
  completeTask,
} from '../../../api/projectMgmtApi';

const TaskPage = ({ project, updateTasks,  handleCompleteProjectUpdate }) => {
  const [newTaskName, setNewTaskName] = useState('');
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editTaskName, setEditTaskName] = useState('');
  const [tasks, setTasks] = useState([]);
  const [isTasksLoaded, setIsTasksLoaded] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);

  const fetchTasks = useCallback(async () => {
    try {
      const projectTasks = await getTasks(project.id);
      setTasks(projectTasks);
      updateTasks(projectTasks);
      setIsTasksLoaded(true);
      const completedIds = projectTasks.filter(d => d.completed).map(d => d.id);
      setCompletedTasks(completedIds);
    } catch (error) {
      console.error('Error fetching tasks:', error.response?.data || error.message);
    }
  }, [project.id, updateTasks]);

  useEffect(() => {
    if (!isTasksLoaded && project.id) { // 프로젝트 ID가 있을 때만 fetchTasks 호출
      fetchTasks();
    }
  }, [fetchTasks, isTasksLoaded, project.id]);

  
  const handleAddTask = async () => {
    if (newTaskName.trim() === '') return; // 입력 필드가 비어있는지 확인
  
    const tempTaskId = Date.now(); // 임시 ID 생성
    const newTask = {
      id: tempTaskId,
      name: newTaskName,
      completed: false, // 기본적으로 미완료 상태
    };
  
    // 임시로 로컬 상태에 새로운 작업 추가
    setTasks((prevTasks) => [...prevTasks, newTask]);
    updateTasks((prevTasks) => [...prevTasks, newTask]);
    setNewTaskName('');
    try {
      // 서버에 작업 생성 요청
      const createdTask = await createTask(project.id, newTaskName);
  
      // 서버에서 생성된 실제 ID로 로컬 상태 업데이트
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === tempTaskId ? { ...task, id: createdTask.id } : task
        )
      );
      updateTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === tempTaskId ? { ...task, id: createdTask.id } : task
        )
      );

      const updatedCompletedTasks = completedTasks.filter(id => id !== createdTask.id);
      setCompletedTasks(updatedCompletedTasks);

      await fetchTasks();
  
      if (project.completed) {
        await handleCompleteProjectUpdate(project.id, false);
      }
    } catch (error) {
      console.error('Error adding task:', error.response?.data || error.message);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== tempTaskId));
      updateTasks((prevTasks) => prevTasks.filter((task) => task.id !== tempTaskId));
    }
  };
  
  
  
  const handleEditTask = async (index, newTaskName) => {
    if (newTaskName.trim() === '') {
      alert('작업 이름을 입력하세요.');
      return;
    }

    const taskToEdit = tasks[index];
    const updatedTasks = tasks.map((t, i) =>
      i === index ? { ...t, name: newTaskName } : t
    );

    setTasks(updatedTasks);
    updateTasks(updatedTasks);
    setEditIndex(null);

    try {
      await updateTask(project.id, taskToEdit.id, newTaskName);
    } catch (error) {
      console.error('Error updating task:', error.response?.data || error.message);
      fetchTasks(); // 변경 사항을 다시 불러옵니다.
    }
  };

  const handleDeleteTask = async (index) => {
    try {
      const task = tasks[index];
      await deleteTask(project.id, task.id);
      
      const updatedTasks = tasks.filter((_, i) => i !== index);
      setTasks(updatedTasks);
  
      // 삭제된 후 남아 있는 작업들이 모두 완료되었는지 확인
      const allCompleted = updatedTasks.every(task => task.completed);
      // 남아있는 작업들이 모두 완료되었다면, 프로젝트를 완료 상태로 업데이트
      if (updatedTasks.length > 0) {
        if (allCompleted) {
          await handleCompleteProjectUpdate(project.id, true);
        } else {
          await handleCompleteProjectUpdate(project.id, false);
        }
      }
      await fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error.response?.data || error.message);
    }
  };
  


  const handleCompleteTaskUpdate = async (taskId, complete = true) => {
    try {
      await completeTask(project.id, taskId, complete);
      // 완료된 작업 목록을 업데이트
      const updatedCompletedTasks = complete ? [...completedTasks, taskId] : completedTasks.filter(id => id !== taskId);
      setCompletedTasks(updatedCompletedTasks);
      // 상태 한번에 업데이트
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, completed: complete } : task
        )
      );
  
      if (updatedCompletedTasks.length === tasks.length && tasks.length > 0) {
        await handleCompleteProjectUpdate(project.id, true);
      } else if (project.completed && updatedCompletedTasks.length !== tasks.length) {
        await handleCompleteProjectUpdate(project.id, false);
      }
  
    } catch (error) {
      console.error('Error completing task:', error.response?.data || error.message);
    }
  };
  


  const handleUpdateTaskDetails = (taskIndex, updatedDetails) => {
    const updatedTasks = tasks.map((task, index) => {
      if (index === taskIndex) {
        return { ...task, details: updatedDetails };
      }
      return task;
    });
    setTasks(updatedTasks);
    updateTasks(updatedTasks);
  };

  const handleDoubleClick = (index) => {
    setEditIndex(index);
    setEditTaskName(tasks[index].name);
  };

  return (
    <div className="project-page-details">
      <div className="task-page-list">
        {tasks.map((task, index) => (
          <div
            key={task.id}
            className={`task-page ${selectedTaskIndex === index ? 'selected' : ''}`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
            onClick={() =>
              setSelectedTaskIndex(selectedTaskIndex === index ? null : index)
            }
            onDoubleClick={() => handleDoubleClick(index)}
          >
            <div className="task-page-content">
              {editIndex === index ? (
                <input
                  type="text"
                  value={editTaskName}
                  onChange={(e) => setEditTaskName(e.target.value)}
                  onBlur={() => handleEditTask(index, editTaskName)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEditTask(index, editTaskName);
                      e.preventDefault();
                    }
                  }}
                  autoFocus
                  className="task-page-input"
                />
              ) : (
                <>
                  <span className={task.completed ? 'completed' : ''}>
                    {task.name}
                  </span>
                  <div
                    className="task-page-actions"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                  {selectedTaskIndex === index && (
                    <button className="task-page-complete-button"
                      onClick={(e) => {e.stopPropagation(); 
                      handleCompleteTaskUpdate(task.id, !task.completed);}} >
                      {task.completed ? '취소' : '완료'}
                    </button>
                    )}
                    {hoverIndex === index &&
                      editIndex !== index &&
                      selectedTaskIndex !== index && (
                        <button
                          className="task-page-delete-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTask(index);
                          }}
                        >
                          삭제
                        </button>
                      )}
                  </div>
                </>
              )}
            </div>
            {selectedTaskIndex === index && (
              <TaskDetailPage
                task={task}
                tasks={tasks}
                updateTaskDetails={(updatedDetails) =>
                  handleUpdateTaskDetails(index, updatedDetails)
                }
                handleCompleteTaskUpdate={handleCompleteTaskUpdate}
              />
            )}
          </div>
        ))}
      </div>
      <div className="add-task-page">
        <input
          type="text"
          value={newTaskName}
          onChange={(e) => setNewTaskName(e.target.value)}
          placeholder="Enter task"
          onKeyDown={(e) => e.key === 'Enter' && handleAddTask()}
          className="task-page-input"
        />
      </div>
    </div>
  );
};

export default TaskPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileBookmarkPage from './FileBookmarkPage';
import SiteBookmarkPage from './SiteBookmarkPage';
import '../../css/pages/BookmarkPage.css';
import { restoreFileBookmark, restoreSiteBookmark } from '../../api/bookmarkApi';

const BookmarkPage = () => {
  // 기본 상태를 세션 스토리지에서 가져오고, 없으면 기본값 true로 설정
  const [isFileBookmark, setIsFileBookmark] = useState(
    sessionStorage.getItem('isFileBookmark') === 'false' ? false : true
  );
  const [isRestoring, setIsRestoring] = useState(false);
  const navigate = useNavigate();

  // 북마크 상태를 세션 스토리지에 저장
  const handleSetFileBookmark = (value) => {
    setIsFileBookmark(value);
    sessionStorage.setItem('isFileBookmark', value); // 세션 스토리지에 저장
  };

  // 파일 북마크 복구 함수
  const handleRestoreFileBookmark = async () => {
    if (isRestoring) return; // 이미 복구 중이면 실행 중지
    setIsRestoring(true); // 복구 진행 중 상태로 설정
    try {
      await restoreFileBookmark();  
      window.location.reload();
    } catch (error) {
      setIsRestoring(false);
      if (error.response && error.response.status === 404) {
        alert('복구할 항목이 없습니다. (휴지통이 비어있습니다)');
      } else {
        console.error('파일 복구 실패:', error);
      }
    }
  };

  // 사이트 북마크 복구 함수
  const handleRestoreSiteBookmark = async () => {
    if (isRestoring) return; // 이미 복구 중이면 실행 중지
    setIsRestoring(true); // 복구 진행 중 상태로 설정
    try {
      await restoreSiteBookmark();
      window.location.reload();
    } catch (error) {
      setIsRestoring(false);
      if (error.response && error.response.status === 404) {
        alert('복구할 항목이 없습니다. (휴지통이 비어있습니다)');
      } else {
        console.error('사이트 복구 실패:', error);
      }
    }
  };

  return (
    <div>
      <div className="page-bookmark-header">
        <h1 className="page-bookmark-title" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
          [Bookmark]
        </h1>
        <div 
          onClick={() => handleSetFileBookmark(true)} 
          className={`page-bookmark-tab ${isFileBookmark ? 'active' : ''}`}
        >
          <h1 className="page-bookmark-tab-title">파일 /</h1>
        </div>
        <div 
          onClick={() => handleSetFileBookmark(false)} 
          className={`page-bookmark-tab ${!isFileBookmark ? 'active' : ''}`}
        >
          <h1 className="page-bookmark-tab-title">사이트 URL</h1>
        </div>
      </div>
      <div className="page-bookmark-content">
        {isFileBookmark ? <FileBookmarkPage /> : <SiteBookmarkPage />}
      </div>
      <div className="restore-button-container">
        {isFileBookmark ? (
          <button onClick={handleRestoreFileBookmark} className="file-page-restore-button">
            복구
          </button>
        ) : (
          <button onClick={handleRestoreSiteBookmark} className="site-page-restore-button">
            복구
          </button>
        )}
      </div>
    </div>
  );
};

export default BookmarkPage;

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // 서버에 쿠키를 보내 인증 상태를 확인
        const response = await axios.get('https://api.todo-pro.store/api/auth/check', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);  
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.error('Authentication check failed:', error);
      } finally {
        setLoading(false);  
      }
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, loading }}>
      {!loading && children}  {/* 로딩이 끝난 후에만 자식 컴포넌트 렌더링 */}
    </AuthContext.Provider>
  );
};

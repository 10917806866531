import axiosInstance from '../auth/axiosConfig';

const fileApiEndpoint = '/api/file-bookmarks';  



// 파일 북마크 조회
export const getFileBookmarks = async () => {
  const response = await axiosInstance.get(`${fileApiEndpoint}`);
  return response.data;
};

// 파일 북마크 추가
export const addFileBookmark = async (file, description) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);

  const response = await axiosInstance.post(`${fileApiEndpoint}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

// 파일 북마크 수정
export const updateFileBookmark = async (id, description) => {
  const response = await axiosInstance.put(`${fileApiEndpoint}/${id}`, {}, {
    params: { description },
  });
  return response.data;
};



// 파일 다운로드
export const downloadFile = async (id) => {
  const response = await axiosInstance.get(`${fileApiEndpoint}/${id}/download`, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const contentDisposition = response.headers['content-disposition'] || '';
  const fileName = contentDisposition.includes('filename=') ? contentDisposition.split('filename=')[1].replace(/"/g, '')  : 'downloaded_file'; 

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', decodeURIComponent(fileName));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url); // Blob URL 해제
};



// 파일 북마크 삭제 (soft delete)
export const softDeleteFileBookmark = async (id) => {
  await axiosInstance.delete(`${fileApiEndpoint}/${id}`);
};

// 파일 복구
export const restoreFileBookmark = async () => {
  const response = await axiosInstance.put(`${fileApiEndpoint}/restore`);
  return response.data;
};


//파일 검색
export const searchFileBookmark = async (keyword) => {
  const response = await axiosInstance.get(`${fileApiEndpoint}/search`, {
    params: {
      keyword: keyword,  
    },
  });
  return response.data;
};



//-----------------------



const siteApiEndpoint = '/api/site-bookmarks';

// 사이트 북마크 생성
export const addSiteBookmark = async (siteName, url) => {
  const response = await axiosInstance.post(siteApiEndpoint, { siteName, url });
  return response.data;
};

// 사이트 북마크 조회
export const getSiteBookmarks = async () => {
  const response = await axiosInstance.get(siteApiEndpoint);
  return response.data;
};

// 사이트 북마크 수정
export const updateSiteBookmark = async (id, siteName, url) => {
  const response = await axiosInstance.put(`${siteApiEndpoint}/${id}`, { siteName, url });
  return response.data;
};


// 사이트 북마크 삭제 (soft delete)
export const softDeleteSiteBookmark = async (id) => {
  const response = await axiosInstance.delete(`${siteApiEndpoint}/${id}`);
  return response.data;
};

// 사이트 북마크 복구
export const restoreSiteBookmark = async () => {
  const response = await axiosInstance.put(`${siteApiEndpoint}/restore`);
  return response.data;
};

//파일 검색
export const searchSiteBookmark = async (keyword) => {
  const response = await axiosInstance.get(`${siteApiEndpoint}/search`, {
    params: {
      keyword: keyword,  
    },
  });
  return response.data;
};


import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getFileBookmarks, addFileBookmark, downloadFile, updateFileBookmark, softDeleteFileBookmark, searchFileBookmark } from '../../../api/bookmarkApi';

const FileSection = () => {
  const [fileBookmarks, setFileBookmarks] = useState([]); // 파일 북마크 목록을 관리하는 상태
  const [file, setFile] = useState(null); // 선택된 파일을 저장하는 상태
  const [description, setDescription] = useState(''); // 설명을 저장하는 상태
  const [editDescription, setEditDescription] = useState(''); // 수정 중인 설명을 저장하는 상태
  const [editId, setEditId] = useState(null); // 현재 수정 중인 북마크의 ID를 저장하는 상태
  const [expandedBookmarkId, setExpandedBookmarkId] = useState(null); // 확장된 북마크의 ID를 저장하는 상태 (북마크 확장/축소)
  
  const fileInputRef = useRef(null); // 파일 입력을 참조하는 ref (DOM 요소에 직접 접근하기 위함)
  const [fileName, setFileName] = useState('파일 선택'); // 파일 선택 버튼에 표시할 텍스트 상태
  const [showCancel, setShowCancel] = useState(false); // 선택 취소 버튼의 표시 여부를 관리하는 상태
  const [clickTimeout, setClickTimeout] = useState(null); // 클릭 타임아웃을 관리하는 상태
  const [keyword, setKeyword] = useState(''); // 검색어 상태

  // 파일 북마크를 서버에서 가져오는 함수
  const fetchFileBookmarks = useCallback(async () => {
    try {
      const data = await getFileBookmarks();
      const filteredData = data.filter(bookmark => !bookmark.deleted); // deleted가 false인 항목만 필터링
      setFileBookmarks(filteredData); // 필터링된 데이터를 상태에 저장
    } catch (error) {
      console.error('Failed to fetch file bookmarks:', error);
    }
  }, []);
  

  // 컴포넌트가 처음 렌더링될 때 파일 북마크 목록을 가져옴
  useEffect(() => {
    fetchFileBookmarks(); // 처음 마운트될 때 파일 북마크 조회
  }, [fetchFileBookmarks]);

  // 파일 선택 시 호출되는 함수
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // 선택된 파일 가져오기
    if (selectedFile) {
      setFile(selectedFile); // 상태에 파일 저장
      setFileName(selectedFile.name); // 파일명을 버튼 텍스트로 설정
    }
  };

  // 파일 선택 취소 시 호출되는 함수
  const handleFileCancel = () => {
    setFile(null); // 파일 상태 초기화
    setFileName('파일 선택'); // 버튼 텍스트 초기화
    fileInputRef.current.value = ''; // 파일 입력란 초기화
  };


// 파일 업로드 시 호출되는 함수
const handleFileUpload = async (e) => {
  e.preventDefault(); // 기본 폼 제출 동작 방지
  try {
    const fileDescription = description.trim() === '' ? ' ' : description;
    await addFileBookmark(file, fileDescription);
    fetchFileBookmarks(); // 업로드 후 파일 북마크 목록을 다시 가져옴
    setFile(null); 
    setDescription(''); 
    setFileName('파일 선택');
    fileInputRef.current.value = ''; // 파일 입력란 초기화
  } catch (error) {
    console.error('Failed to upload file bookmark:', error);
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 404) {
        alert('업로드할 파일이 존재하지 않습니다.');
      } else {
        alert('파일 업로드 실패\n잠시 후 다시 시도해 주세요.');
      }
    } else {
      alert('파일 업로드 실패\n잠시 후 다시 시도해 주세요.');
    }
  }
};



  // 북마크를 클릭하여 파일 다운로드를 시작하는 함수
  const handleBookmarkClick = async (bookmarkId) => {
    if (editId !== bookmarkId) {
      try {
        await downloadFile(bookmarkId); // 서버에서 파일 다운로드
      } catch (error) {
        console.error('Failed to download file:', error); // 에러 발생 시 콘솔에 출력
      }
    } else {
      setEditId(null); // 수정 취소
      setEditDescription(''); // 수정 텍스트 초기화
    }
  };

  // 북마크 설명을 수정하기 위해 호출되는 함수
  const handleEditClick = (bookmarkId, currentDescription) => {
    setEditId(bookmarkId); // 수정할 북마크의 ID 설정
    setEditDescription(currentDescription); // 현재 설명을 수정 상태에 설정
    setExpandedBookmarkId(bookmarkId); // 수정 중인 북마크를 확장
  };

  // 북마크 설명 수정 후 저장하는 함수
  const handleEditSubmit = async (bookmarkId) => {
    // 기존 북마크 목록을 업데이트하여 새로운 설명을 반영
    setFileBookmarks((prevBookmarks) =>
      prevBookmarks.map((bookmark) =>
        bookmark.id === bookmarkId ? { ...bookmark, description: editDescription } : bookmark
      )
    );

    setEditId(null); // 수정 상태 초기화
    setEditDescription(''); // 수정 텍스트 초기화

    try {
      await updateFileBookmark(bookmarkId, editDescription); // 서버에 수정된 설명 저장
    } catch (error) {
      console.error('Failed to update file bookmark:', error); // 에러 발생 시 콘솔에 출력
      fetchFileBookmarks(); // 실패 시 목록을 다시 가져와 상태 동기화
    }
  };

  // 북마크를 삭제하는 함수
  const handleDeleteClick = async (bookmarkId) => {
    try {
      await softDeleteFileBookmark(bookmarkId); // 서버에서 북마크 삭제
      fetchFileBookmarks(); // 삭제 후 파일 북마크 목록을 다시 가져옴
    } catch (error) {
      console.error('Failed to delete file bookmark:', error); // 에러 발생 시 콘솔에 출력
    }
  };


  // 북마크 검색
  const handleSearch = async () => {
    if (!keyword.trim()) { // trim()을 사용해 공백 제거 후 검사
      window.location.reload();
      return; 
    }
    try {
      const data = await searchFileBookmark(keyword);
      setFileBookmarks(data);  // 검색 결과를 상태에 저장
      setKeyword('');  // 검색 완료 후 검색창 비우기
    } catch (error) {
      console.error("Failed to search file bookmarks:", error);
    }
  };


  // 북마크 확장/축소를 처리하는 함수
  const handleBookmarkExpand = (bookmarkId) => {
    setExpandedBookmarkId(expandedBookmarkId === bookmarkId ? null : bookmarkId); // 클릭한 북마크를 확장/축소
  };

  // 클릭 이벤트 처리 함수
  const handleBookmarkClickOrEdit = (bookmarkId, description) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout); // 기존의 클릭 타임아웃을 제거하여 더블클릭을 방지
      setClickTimeout(null);
      handleEditClick(bookmarkId, description); // 더블클릭인 경우 수정 모드로 전환
    } else {
      const timeout = setTimeout(() => {
        handleBookmarkExpand(bookmarkId); // 단일 클릭인 경우 북마크 확장/축소
        setClickTimeout(null);
      }, 300); // 300ms 후에 단일 클릭 처리
      setClickTimeout(timeout); // 타임아웃을 상태로 저장
    }
  };

  // 파일명에서 확장자를 제거하는 함수
  const getFileNameWithoutExtension = (fileName) => {
    return fileName.split('.').slice(0, -1).join('.'); // 확장자를 제외한 파일명 반환
  };

  return (
    <div>
      {/* 검색어 입력 및 검색 버튼 추가 */}
      <div className="file-search-section">
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)} // 검색어 상태 업데이트
          placeholder="검색어 입력"
          className="file-search-input"
        />
        <button onClick={handleSearch} className="file-search-button">검색</button>
      </div>

      <form onSubmit={handleFileUpload} className="file-upload-form">
        <div 
          className="file-upload-wrapper"
          onMouseEnter={() => setShowCancel(true)}
          onMouseLeave={() => setShowCancel(false)}
        >
          <label htmlFor="file-upload" className="file-upload-label">
            {fileName}
          </label>
          <input 
            id="file-upload"
            type="file" 
            onChange={handleFileChange} 
            ref={fileInputRef} 
            style={{ display: 'none' }}
          />
          {showCancel && file && (
            <button type="button" className="file-cancel-button" onClick={handleFileCancel}>
              선택 취소
            </button>
          )}
        </div>
        <div className="upload-description-wrapper">
          <textarea
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            placeholder="Description" 
            className="description-input" 
          />
          <button type="submit" className="upload-button">추가</button>
        </div>
      </form>

      <ul className="file-bookmarks">
        {fileBookmarks.map((bookmark) => (
          <li 
            key={bookmark.id} 
            className={`file-bookmark ${expandedBookmarkId === bookmark.id || editId === bookmark.id ? 'expanded' : ''}`} 
            onClick={() => handleBookmarkExpand(bookmark.id)}
          >
            <span 
              className="file-name" 
              title={bookmark.fileName}
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick(bookmark.id); // 수정 중이면 수정 취소, 아니면 다운로드
              }}
            >
              {getFileNameWithoutExtension(bookmark.fileName)}
            </span>
            {editId === bookmark.id ? (
              <textarea
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                className="edit-textarea"
              />
            ) : (
              <p
                onClick={() => handleBookmarkClickOrEdit(bookmark.id, bookmark.description)}  // 더블클릭과 클릭 모두 처리
              >
                {bookmark.description}
              </p>
            )}
            {editId === bookmark.id && (
              <button
                onClick={() => handleEditSubmit(bookmark.id)}
              >
                저장
              </button>
            )}
            {editId !== bookmark.id && (
              <div className="file-bookmark-buttons">
                <button onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(bookmark.id);
                }}>삭제</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileSection;
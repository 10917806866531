import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/loginApi'; 
import { AuthContext } from '../../auth/AuthContext';
import '../../css/LoginHome.css'; 

const LoginPage = () => {
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();  

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const loginDto = { username: loginId, password };  
            const response = await login(loginDto);
            if (response.status === 200) {
                setIsAuthenticated(true);
                navigate('/');
            }
        } catch (error) {
            alert('아이디, 비밀번호 오류!');
            console.error('로그인 오류:', error);
        }
    };
    

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <div className="auth-container">
            <h2>로그인</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div>
                    <label>아이디: </label>
                    <input
                        type="text"
                        value={loginId}
                        onChange={(e) => setLoginId(e.target.value)}
                        required  
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <label>비밀번호: </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required  
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <button type="submit">확인</button>
                    <button type="button" onClick={handleCancel} style={{ marginLeft: '10px' }}>
                        취소
                    </button>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
